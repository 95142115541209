import Phaser from "phaser";
import BootScene from "./scenes/BootScene";
import GameScene from "./scenes/GameScene";
import EndScene from "./scenes/EndScene";

export const gameConfig: Phaser.Types.Core.GameConfig = {
  title: "Crypto Mario Game",
  parent: "phaser-game", // id to mount

  type: Phaser.AUTO,
  width: 800,
  height: 600,

  dom: {
    createContainer: true,
  },

  scale: {
    mode: Phaser.Scale.FIT,
    parent: "phaser-example",
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: 800,
    height: 600,
  },

  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 300 },
      debug: false,
    },
  },
  backgroundColor: "#333333",

  scene: [BootScene, GameScene, EndScene],
};
