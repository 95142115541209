import Phaser from "phaser";

class EndScene extends Phaser.Scene {
  private sky?: Phaser.GameObjects.Image;

  constructor() {
    super({
      key: "EndScene",
    });
  }

  preload(): void {
    this.load.image("sky", "assets/sky.png");
  }

  create(): void {
    this.sky = this.add.image(0, 0, "sky");
    // this.addNameText = this.add.text(10, 10, "Your name:", {
    //   color: "#ffffff",
    // });
    // this.addNameText!.visible = false;

    // this.rect = this.add.rectangle(0, 0, 350, 100, 0, 0);
    // this.rect.isStroked = true;

    // this.textInput = this.add.dom(0, 0, "#name", "display: block");

    // this.connectWalletButton = this.add.text(0, 0, this.TEXT_CONNECT_BUTTON, {
    //   color: "#333",
    // });
    // this.connectWalletButton.setInteractive({ useHandCursor: true });
    // this.connectWalletButton.on("pointerdown", () => {
    //   // connectWallet();
    // });

    // this.playButton = this.add.text(0, 0, this.TEXT_START_BUTTON, {
    //   color: "#333",
    // });
    // this.playButton.visible = false;
    // this.playButton.setInteractive({ useHandCursor: true });
    // this.playButton.on("pointerdown", () => {
    //   // this.startGame("");
    //   this.readyToStart = true;
    // });
    Phaser.Display.Align.In.Center(this.sky, this.add.zone(400, 300, 800, 600));
    // Phaser.Display.Align.In.Center(this.connectWalletButton, this.sky);
    // Phaser.Display.Align.In.Center(this.rect, this.sky);
    // Phaser.Display.Align.In.Center(this.addNameText, this.sky, -100, -20);
    // Phaser.Display.Align.In.Center(this.textInput, this.sky, 50, -20);
    // Phaser.Display.Align.In.Center(this.playButton, this.sky, 0, 20);
  }
}

export default EndScene;
