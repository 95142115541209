import Phaser from "phaser";

class BootScene extends Phaser.Scene {
  private readyToStart = false;
  // private connectWalletButton?: Phaser.GameObjects.Text;
  private playButton?: Phaser.GameObjects.Text;
  private playButtonColorP?: Phaser.GameObjects.Text;
  private playButtonColorL?: Phaser.GameObjects.Text;
  private playButtonColorA?: Phaser.GameObjects.Text;
  private playButtonColorY?: Phaser.GameObjects.Text;

  private rulesHeadingR?: Phaser.GameObjects.Text;
  private rulesHeadingU?: Phaser.GameObjects.Text;
  private rulesHeadingL?: Phaser.GameObjects.Text;
  private rulesHeadingE?: Phaser.GameObjects.Text;
  private rulesHeadingS?: Phaser.GameObjects.Text;
  private rulesHeadingColon1?: Phaser.GameObjects.Text;
  private rulesHeadingColon2?: Phaser.GameObjects.Text;

  private textInput?: Phaser.GameObjects.DOMElement;
  private addNameText?: Phaser.GameObjects.Text;
  private rect?: Phaser.GameObjects.Rectangle;
  private sky?: Phaser.GameObjects.Image;
  private TEXT_START_BUTTON = "Play";
  private rulesHeading?: Phaser.GameObjects.Text;
  private firstRule?: Phaser.GameObjects.Text;
  private secondRule?: Phaser.GameObjects.Text;
  private thirdRule?: Phaser.GameObjects.Text;
  private fourthRule?: Phaser.GameObjects.Text;

  constructor() {
    super({
      key: "BootScene",
    });
  }

  preload(): void {
    this.load.image("sky", "assets/sky.png");
    // this.actions = bindActionCreators(actionCreators, store.dispatch);
  }
  create(): void {
    this.sky = this.add.image(0, 0, "sky");
    this.addNameText = this.add.text(10, 10, "Your name:", {
      color: "#262626",
      fontFamily: "SuperMarioFont",
      fontSize: "18px",
    });
    this.addNameText!.visible = false;

    this.textInput = this.add
      .dom(400, 310)
      .createFromHTML(
        '<input type="text" id="name" placeholder="Enter player\'s name" style="display: block; font-family: SuperMarioFont; max-width: 170px; height: 20px;">'
      );

    this.rect = this.add.rectangle(0, 0, 350, 100, 0, 0);
    this.rect.isStroked = true;

    // this.textInput = this.add.dom(0, 0, "input", "display: block");

    this.rulesHeading = this.add.text(0, 0, "RULES:", {
      color: "#262626",
      fontFamily: "SuperMarioFont",
      fontSize: "40px",
    });

    this.rulesHeadingR = this.add.text(0, 0, "R", {
      color: "#019cda",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });

    this.rulesHeadingU = this.add.text(0, 0, "U", {
      color: "#fcd002",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.rulesHeadingL = this.add.text(0, 0, "L", {
      color: "#e71f08",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.rulesHeadingE = this.add.text(0, 0, "E", {
      color: "#41b131",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.rulesHeadingS = this.add.text(0, 0, "S", {
      color: "#fcd002",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.rulesHeadingColon1 = this.add.text(0, 0, ".", {
      color: "#e71f08",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.rulesHeadingColon2 = this.add.text(0, 0, ".", {
      color: "#e71f08",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });

    this.firstRule = this.add.text(
      0,
      0,
      `1. Use arrow keys to move left, right or jump`,
      {
        color: "#262626",
        fontFamily: "SuperMarioFont",
        fontSize: "20px",
      }
    );

    this.secondRule = this.add.text(
      0,
      0,
      `2. Collect coins to increase score`,
      {
        color: "#262626",
        fontFamily: "SuperMarioFont",
        fontSize: "20px",
      }
    );

    this.thirdRule = this.add.text(
      0,
      0,
      `3. After collecting all couns, an enemy will appear\n and new coins will spawn`,
      {
        color: "#262626",
        fontFamily: "SuperMarioFont",
        fontSize: "20px",
      }
    );

    this.fourthRule = this.add.text(
      0,
      0,
      `4. Avoid the enemy and collect as many coins as possible`,
      {
        color: "#262626",
        fontFamily: "SuperMarioFont",
        fontSize: "20px",
      }
    );

    this.playButton = this.add.text(0, 0, this.TEXT_START_BUTTON, {
      color: "#333",
      fontFamily: "SuperMarioFont",
      fontSize: "40px",
    });
    this.playButtonColorP = this.add.text(0, 0, "P", {
      color: "#019cda",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.playButtonColorL = this.add.text(0, 0, "L", {
      color: "#fcd002",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.playButtonColorA = this.add.text(0, 0, "A", {
      color: "#e71f08",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.playButtonColorY = this.add.text(0, 0, "Y", {
      color: "#41b131",
      fontFamily: "SuperMarioFont",
      fontSize: "28px",
    });
    this.playButton.visible = false;
    this.playButton.setInteractive({ useHandCursor: true });
    this.playButton.on("pointerdown", () => {
      // this.startGame("");
      this.readyToStart = true;
    });
    Phaser.Display.Align.In.Center(this.sky, this.add.zone(400, 300, 800, 600));
    // Phaser.Display.Align.In.Center(this.connectWalletButton, this.sky);
    Phaser.Display.Align.In.Center(this.rect, this.sky, 0.5, 0);
    Phaser.Display.Align.In.Center(this.addNameText, this.sky, -100, -20);
    Phaser.Display.Align.In.Center(this.textInput, this.sky, 50, -20);
    Phaser.Display.Align.In.Center(this.playButton, this.sky, 0, 20);
    Phaser.Display.Align.In.Center(this.playButtonColorP, this.sky, -40, 20);
    Phaser.Display.Align.In.Center(this.playButtonColorL, this.sky, -15, 20);
    Phaser.Display.Align.In.Center(this.playButtonColorA, this.sky, 12, 20);
    Phaser.Display.Align.In.Center(this.playButtonColorY, this.sky, 40, 20);
    Phaser.Display.Align.In.TopLeft(this.rulesHeading, this.sky, -20, -10);
    Phaser.Display.Align.In.TopLeft(this.rulesHeadingR, this.sky, -23, -15);
    Phaser.Display.Align.In.TopLeft(this.rulesHeadingU, this.sky, -50, -15);
    Phaser.Display.Align.In.TopLeft(this.rulesHeadingL, this.sky, -78, -18);
    Phaser.Display.Align.In.TopLeft(this.rulesHeadingE, this.sky, -105, -15);
    Phaser.Display.Align.In.TopLeft(this.rulesHeadingS, this.sky, -130, -15);
    Phaser.Display.Align.In.TopLeft(
      this.rulesHeadingColon1,
      this.sky,
      -153,
      -6
    );
    Phaser.Display.Align.In.TopLeft(
      this.rulesHeadingColon2,
      this.sky,
      -153,
      -19
    );
    Phaser.Display.Align.In.TopLeft(this.firstRule, this.sky, -20, -60);
    Phaser.Display.Align.In.TopLeft(this.secondRule, this.firstRule, 0, -25);
    Phaser.Display.Align.In.TopLeft(this.thirdRule, this.secondRule, 0, -25);
    Phaser.Display.Align.In.TopLeft(this.fourthRule, this.thirdRule, 0, -45);
  }

  update(): void {
    if (this.textInput) {
      const inputElement = this.textInput.node.querySelector(
        "#name"
      ) as HTMLInputElement;

      this.game.registry.set("playerName", inputElement.value);
    }

    if (!this.readyToStart) {
      this.playButton!.visible = true;
      this.rect!.visible = true;
      this.addNameText!.visible = true;
      // this.connectWalletButton!.visible = false;
    } else if (this.readyToStart) {
      this.playButton?.setText("Confirm transaction and wait for start");
      Phaser.Display.Align.In.Center(this.playButton!, this.sky!);
    }
    if (this.readyToStart) {
      this.playButton!.visible = false;
      this.scene.start("GameScene");
      this.scene.bringToTop("GameScene");
    }
  }
}

export default BootScene;
