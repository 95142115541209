import React, { useEffect } from "react";

import Phaser from "phaser";
import { gameConfig as game } from "./gameConfig";
import { GameMountPoint } from "../components";

interface ParentCompProps {}

function Game(props: ParentCompProps) {
  const [playerName, setPlayerName] = React.useState("");

  const Game = class extends Phaser.Game {
    constructor() {
      super(game);
    }
  };
  useEffect(() => {
    window.game = new Game();
  }, []);

  return (
    <GameMountPoint id="phaser-game">
      <input
        type="text"
        id="name"
        style={{ display: "none", maxWidth: 184 }}
        value={playerName}
        onInput={(e) => setPlayerName((e.target as HTMLInputElement).value)}
      />
    </GameMountPoint>
  );
}

export default Game;
